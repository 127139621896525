<template>
  <v-dialog v-model="syncedOpened" width="1200" overlay-opacity="0.8" scrollable>
    <div class="v-card">
      <div ref="menu" class="menues">
        <h2>{{ menuItem?.name }}</h2>
        <div v-if="menuItem?.children" class="menu">
          <div class="categories">
            <h3>Page</h3>
            <ul class="categories__list">
              <li v-for="(menuItem, index) in menu.children"
                :class="activeMenu === index ? 'categories__item categories__item_active' : 'categories__item'"
                :key="menuItem.id" v-on:click="setActiveMenu(index)">
                <p>{{ menuItem.name }}</p>
                <v-btn v-on:click="download" class="ml-1" :isLoading="isLoading" icon>
                  <v-icon>mdi-file-arrow-up-down</v-icon>
                </v-btn>
              </li>
            </ul>
            <h3>Categories</h3>
            <ul class="categories__list">
              <li :class="activeCategory === 'All disabled' ? 'categories__item categories__item_active' : 'categories__item'
              " v-on:click="setActiveCategory('All disabled')">
                <p>All disabled</p>
                <span>{{ allDisabled.length }}</span>
              </li>

              <li
                :class="activeCategory === 'All disabled from POS' ? 'categories__item categories__item_active' : 'categories__item'"
                v-on:click="setActiveCategory('All disabled from POS')">
                <p>All disabled from POS</p>
                <span>{{ allDisabledFromPOS.length }}</span>
              </li>

              <li
                :class="activeCategory === 'Broken Combos' ? 'categories__item categories__item_active' : 'categories__item'"
                v-on:click="setActiveCategory('Broken Combos')">
                <p>Broken Combos</p>
                <span>{{ allBrokenCombos.length }}</span>
              </li>

              <li v-for="(category, index) in menuItem.children"
                :class="activeCategory === index ? 'categories__item categories__item_active' : 'categories__item'"
                :key="category.id" v-on:click="setActiveCategory(index)">
                <p>{{ category.name }}</p>
                <span>{{ category.children.length }}</span>
              </li>
            </ul>
          </div>
          <div class="dishes">
            <Button
              v-if="!activeDish[0] && !(['All disabled from POS', 'All disabled', 'Broken Combos'].includes(activeCategory))"
              text="Disable/Enable All dishes in Category" class="standart-button-content-width float-right mb-4 mx-3"
              type="button" @handleClick="disableAllDishesInCategory">
            </Button>
            <Button v-if="activeDish[0] && activeDish[0]?.children && activeDish[0].id[0] !== 'i'"
              text="Disable/Enable All Items" class="standart-button-content-width float-right mb-4 mx-3" type="button"
              @handleClick="disableAllDishesInCombo">
            </Button>
            <div
              v-if="activeDish && !activeDish[0] && !(['All disabled', 'All disabled from POS', 'Broken Combos'].includes(activeCategory))"
              class="dish">
              <ul class="dish__list">
                <li class="dish__item" v-for="dish in menuItem.children[activeCategory].children" :key="dish.id"
                  @click="setActiveDish(dish)">
                  <p>{{ dish.name }}</p>
                  <span v-if="dish.isBrokenCombo || dish.isEmpty"><v-icon large
                      color="red">mdi-alert-circle</v-icon></span>
                  <span v-if="dish.children">+Options</span>
                  <span v-if="dish.isRequiredCombo">Required</span>
                  <button :class="getDishClass(dish)" :disabled="getButtonDisabledState(dish)"
                    v-on:click.stop="setIsEnabled(dish)">
                    {{ getDishStatus(dish) }}
                  </button>
                </li>
              </ul>
            </div>

            <div v-if="activeDish && !activeDish[0] && activeCategory === 'All disabled'" class="dish">
              <ul class="dish__list">
                <li class="dish__item" v-for="disabled in allDisabled" :key="disabled.dish.id"
                  @click="setActiveDish(disabled.dish)">
                  <p>{{ disabled.dish.name }}</p>
                  <span>{{ disabled.status }}</span>
                  <button :class="disabled.dish.isEnabled ? 'enabled' : 'disabled'"
                    v-on:click.stop="setIsEnabled(disabled.dish)">
                    {{ disabled.dish.isEnabled ? 'Enabled' : 'Disabled' }}
                  </button>
                </li>
              </ul>
            </div>

            <div v-if="activeDish && !activeDish[0] && activeCategory === 'All disabled from POS'" class="dish">
              <ul class="dish__list">
                <li class="dish__item" v-for="disabled in allDisabledFromPOS" :key="disabled.dish.id"
                  @click="setActiveDish(disabled.dish)">
                  <p>{{ disabled.dish.name }}</p>
                  <span>{{ disabled.status }}</span>
                  <button class='disabled' disabled="true">
                    Disabled from POS
                  </button>
                </li>
              </ul>
            </div>

            <div v-if="activeDish && !activeDish[0] && activeCategory === 'Broken Combos'" class="dish">
              <ul class="dish__list">
                <li class="dish__item" v-for="broken in allBrokenCombos" :key="broken.dish.id"
                  @click="setActiveDish(broken.dish)">
                  <span>{{ broken.dish.name }}</span>
                  <span>{{ broken.status }}</span>
                  <span><v-icon large color="red">mdi-alert-circle</v-icon></span>
                  <button class='disabled' disabled="true">
                    Broken
                  </button>
                </li>
              </ul>
            </div>

            <div v-if="activeDish[0]">
              <div class="disha-detail">
                <div class="disha-detail__header">
                  <button class="disha-detail__header_back" @click="closeDish">
                    <img :src="require(`@/assets/icons/back.svg`)" alt="" />
                    <span> back</span>
                  </button>
                  <p>{{ activeDish[0].name }}</p>
                  <span v-if="activeDish[0].isRequiredCombo">Required</span>
                  <button :class="getDishClass(activeDish[0])" disabled="true" @click.stop="setIsEnabled(activeDish[0])">
                    {{ getDishStatus(activeDish[0]) }}
                  </button>
                </div>
                <div v-if="activeDish[0].children">
                  <ul>
                    <li class="dish__item" v-for="i in activeDish[0].children" :key="i.id" @click="setActiveDish(i)">
                      <p>{{ i.name }}</p>
                      <span v-if="i.children">+Options</span>
                      <span v-if="i.isRequiredCombo">Required</span>
                      <button :class="getDishClass(i)" :disabled="getButtonDisabledState(i) || i.children"
                        v-on:click.stop="setIsEnabled(i)">
                        {{ getDishStatus(i) }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="noMenuItems">
          <p>No menu items</p>
        </div>
      </div>
      <div class="v-card-actions buttons-actions">
        <div class="up-down">
          <button styleType="secondary" class="standart-button-content-width mr-2 px-2" type="button" @click="scrollByUp">
            <img :src="require(`@/assets/icons/up.svg`)" alt="Scroll up" />
          </button>
          <button styleType="secondary" class="standart-button-content-width mr-2 px-2" type="button"
            @click="scrollByDown">
            <img :src="require(`@/assets/icons/down.svg`)" alt="Scroll down" />
          </button>
        </div>
        <div>
          <p>Last update:</p>
          <span>{{ lastUpdateTime }}</span>
        </div>
        <div class="d-flex align-center justify-end">
          <Button text="Update menu" class="standart-button-content-width mr-2" type="button" :isLoading="isLoading"
            @handleClick="updateMenu" />
          <Button styleType="secondary" text="Cancel" class="standart-button-content-width mr-2" type="button"
            @handleClick="closeModal" />
          <Button text="Save" class="standart-button-content-width" type="button" :isLoading="isLoading"
            @handleClick="save" />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Button from '@/components/commonComponents/Button';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import * as dayjs from 'dayjs';
import FileSaver from 'file-saver';

export default {
  name: 'MenuModal',
  data() {
    return {
      disableToggleFlag: false,
      activeMenu: 0,
      activeCategory: 0,
      activeDish: [],
    };
  },
  components: {
    Button,
  },
  model: {
    prop: 'opened',
    event: 'opened:update',
  },
  props: {
    opened: {
      type: Boolean,
    },
    venueId: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      getMenu: 'venues/getMenu',
      getGeneralSettings: 'app/getGeneralSettings',
      getLastUpdateTime: 'venues/getLastUpdateTime',
      isLoading: 'venues/isLoading',
    }),
    menu() {
      return this.getMenu;
    },
    lastUpdateTime() {
      if (localStorage.getItem('settings')) {
        this.setGeneralSettings(JSON.parse(localStorage.getItem('settings')));
      }
      const settings = { ...this.getGeneralSettings };
      const date = this.getLastUpdateTime;

      if (settings.timeFormat === 2) {
        return dayjs(date).format('MMMM D, YYYY HH:mm');
      } else {
        return dayjs(date).format('MMMM D, YYYY h:mm A');
      }
    },
    menuItem() {
      return this.menu.children?.[this.activeMenu];
    },
    allDisabled() {
      function findDisabledDishId(children, arrId = []) {
        const disabled = [];

        children.forEach(item => {
          if (item.isEnabled === false && !arrId.includes(item.id)) {
            switch (item.id[0]) {
              case 'i':
                disabled.push({ dish: item, status: 'Item' });
                break;
              case 'm':
                disabled.push({ dish: item, status: 'Modifier' });
                break;
              case 'g':
                disabled.push({ dish: item, status: 'Modifier group' });
                break;
            }
            arrId.push(item.id);
          } else {
            if (item.children) {
              disabled.push(...findDisabledDishId(item.children, arrId));
            } else {
              return [];
            }
          }
        });
        return disabled;
      }

      return findDisabledDishId(this.menu.children[this.activeMenu].children);
    },
    allDisabledFromPOS() {
      function findDisabledDishId(children, arrId = []) {
        const disabled = [];

        children.forEach(item => {
          if (item.isDisabledInRk && !arrId.includes(item.id)) {
            switch (item.id[0]) {
              case 'i':
                disabled.push({ dish: item, status: 'Item' });
                break;
              case 'm':
                disabled.push({ dish: item, status: 'Modifier' });
                break;
              case 'g':
                disabled.push({ dish: item, status: 'Modifier group' });
                break;
            }
            arrId.push(item.id);
            // if (item.children) {
            //   disabled.push(...findDisabledDishId(item.children, arrId));
            // }
          } else {
            if (item.children) {
              disabled.push(...findDisabledDishId(item.children, arrId));
            } else {
              return [];
            }
          }
        });

        return disabled;
      }

      return findDisabledDishId(this.menuItem.children);
    },
    allBrokenCombos() {
      function findBrokenDishes(children, arrId = []) {
        const broken = [];

        children.forEach(item => {
          if (item.isBrokenCombo) {
            switch (item.id[0]) {
              case 'i':
                broken.push({ dish: item, status: 'Item' });
                break;
            }
            arrId.push(item.id);
            if (item.children) {
              broken.push(...findBrokenDishes(item.children, arrId));
            }
          }
          else {
            if (item.children) {
              broken.push(...findBrokenDishes(item.children, arrId));
            } else {
              return [];
            }
          }
        });

        return broken;
      }
      return findBrokenDishes(this.menuItem.children);
    },
    syncedOpened: {
      get() {
        return this.opened;
      },
      set(val) {
        return this.$emit('opened:update', val);
      },
    },
  },
  methods: {
    ...mapMutations({
      setEnabladDish: 'venues/setEnabladDish',
      toggleDisableStatus: 'venues/toggleDisableStatus',
      setGeneralSettings: 'app/setGeneralSettings',
      setLastUpdateTime: 'venues/setLastUpdateTime',
    }),
    ...mapActions({
      changeMenu: 'venues/changeMenu',
      fetchMenu: 'venues/fetchMenu',
      downloadMenu: 'venues/downloadMenu',
    }),

    getDishStatus(dish) {
      if (dish.isEmpty) return "Empty";
      if (dish.isBrokenCombo) return "Broken"
      if (dish.isDisabledInRk) return "Disabled from POS"
      return dish.isEnabled ? "Enabled" : "Disabled"
    },
    getDishClass(dish) {
      if (dish.isEmpty) return "disabled";
      if (dish.isBrokenCombo) return "disabled"
      if (dish.isDisabledInRk) return "disabled"
      return dish.isEnabled ? "enabled" : "disabled"
    },
    getButtonDisabledState(dish) {
      if (dish.isEmpty) return true;
      if (dish.isBrokenCombo) return true
      if (dish.isDisabledInRk) return true
      return false
    },
    closeModal() {
      this.syncedOpened = false;
      this.$emit('closeMenuModal');
    },
    async download() {
      const obj = {
        venueId: this.venueId,
        identifiers: {},
      };
      function findDisabledDish(arr) {
        arr.forEach(element => {
          function findDisabledDishId(children) {
            const disabled = [];

            children.forEach(item => {
              if (item.isEnabled === false) {
                disabled.push(item.id);
              } else {
                if (item.children) {
                  disabled.push(...findDisabledDishId(item.children));
                } else {
                  return [];
                }
              }
            });
            return disabled;
          }

          obj.identifiers[element.name] = findDisabledDishId(element.children);
        });
        return obj;
      }
      const links = await this.downloadMenu(findDisabledDish(this.menu.children));
      await links.forEach(link => {
        const fileName = link.substring(link.lastIndexOf('/') + 1);
        FileSaver.saveAs(link, fileName);
      });
    },
    async save() {
      const obj = {
        venueId: this.venueId,
        identifiers: {},
      };
      function findDisabledDish(arr) {
        arr.forEach(element => {
          function findDisabledDishId(children) {
            const disabled = [];

            children.forEach(item => {
              if (item.isEnabled === false) {
                disabled.push(item.id);
              } else {
                if (item.children) {
                  disabled.push(...findDisabledDishId(item.children));
                } else {
                  return [];
                }
              }
            });
            return disabled;
          }

          obj.identifiers[element.name] = findDisabledDishId(element.children);
        });
        return obj;
      }

      await this.changeMenu(findDisabledDish(this.menu.children)).then((data) => {
        const date = new Date();
        this.setLastUpdateTime(date);
        localStorage.setItem(
          this.venueId,
          JSON.stringify({
            menu: data,
            lastUpdateTime: date,
          }),
        );
      });

    },
    setActiveCategory(categoryIndex) {
      this.activeDish = [];
      this.activeCategory = categoryIndex;
    },
    setActiveMenu(menuIndex) {
      this.activeMenu = menuIndex;
      this.activeDish = [];
      this.activeCategory = 0;
    },
    setIsEnabled(item) {
      this.setEnabladDish({ dishId: item.id, menuIndex: this.activeMenu });
    },
    setActiveDish(dish) {
      this.activeDish = [dish, ...this.activeDish];
    },
    closeDish() {
      this.activeDish.shift();
    },
    scrollByUp() {
      this.$refs.menu.scrollBy({
        top: -50,
        left: 0,
        behavior: 'smooth',
      });
    },
    scrollByDown() {
      this.$refs.menu.scrollBy({
        top: 50,
        left: 0,
        behavior: 'smooth',
      });
    },
    async updateMenu() {
      const menu = await this.fetchMenu(this.venueId);
      const date = new Date();
      this.setLastUpdateTime(date);
      localStorage.setItem(this.venueId, JSON.stringify({ menu: menu, lastUpdateTime: date }));
    },
    disableAllDishesInCategory() {
      this.toggleDisableStatus({ menuIndex: this.activeMenu, categoryIndex: this.activeCategory, status: this.disableToggleFlag })
      this.disableToggleFlag = !this.disableToggleFlag
    },
    disableAllDishesInCombo() {
      this.activeDish[0].children.forEach(item => {
        if (!item.isBrokenCombo && !item.isDisabledInRk) item.isEnabled = !item.isEnabled
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.menues {
  max-height: 800px;
  background-color: #fff;
  overflow-y: scroll;

  h2 {
    text-transform: uppercase;
    text-align: center;
    margin: 20px 0;
  }
}

.menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 18px;
}

.noMenuItems {
  font-size: 2rem;
  margin: 0 auto 110px auto;
}

.categories {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & h3 {
    color: #5b7ffe;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    p {
      margin-bottom: 0;
    }

    span {
      margin-left: 20px;
    }

    &_active {
      background-color: rgb(183, 197, 247);

      color: #5b7ffe;
    }
  }
}

.dishes {
  width: 800px;
  margin: 0 auto;
}

.disha-detail {
  &__header {
    width: 100%;
    margin: 20px 0;
    font-size: 18px;
    list-style-type: none;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;

    &_back {
      display: flex;
      align-items: center;
      margin: 20px;
      line-height: 24px;
      background-color: #5170e0;
      color: #fff;
      display: block;
      cursor: pointer;

      img path {
        fill: #fff;
      }
    }

    p {
      margin-bottom: 0;
    }

    button {
      border-radius: 5px;
      padding: 5px 10px;
    }

    .enabled {
      background-color: #5b7ffe;
      color: #fff;
    }

    .disabled {
      background-color: red;
      color: #fff;
    }
  }
}

.dish {
  ul {
    padding: 0;
  }

  &__item {
    width: 100%;
    margin: 20px 0;
    font-size: 18px;
    list-style-type: none;
    border: 0.5px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    p {
      margin-bottom: 0;
    }

    button {
      border-radius: 5px;
      padding: 5px 10px;
    }

    .enabled {
      background-color: #5170e0;
      color: #fff;
    }

    .disabled {
      background-color: red;
      color: #fff;
    }
  }
}

.up-down {
  button {
    background-color: #5170e0;
    border-radius: 5px;
  }
}

.buttons-actions {
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 25px;
}
</style>
 